.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 16px;
  width: 100%;

  .form {
    margin-bottom: 16px;
    width: 300px;
  }
}
