@import '../../../styles/_colors.scss';

.game-dividion-container {
  align-items: center;
  display: flex;
  margin-top: 10px;
  width: 100%;
}

.game-dividion-selector {
  padding: 0;
  display: flex;
  height: fit-content;
  margin: 0 0 0 1rem;

  .game-dividion-tab {
    font-family: DM Sans;
    font-size: 0.7rem;
    font-weight: 500;
    color: $gray-40;
    text-transform: uppercase;
    margin-right: 0.6rem;
    min-width: 4rem;
    cursor: pointer;
    border: 1px solid;
    border-radius: 5px;
    padding: 0.1rem 2px;
    display: flex;
    justify-content: center;
  }

  .game-dividion-tab.selected {
    color: $wt-green;
  }
}
