@import '../../../styles/_colors.scss';

.legend-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 8px;
  padding-left: 48px;

  .index {
    align-items: center;
    display: flex;
    gap: 8px;

    .high,
    .low {
      border-radius: 6px;
      height: 25px;
      width: 16px;
    }

    .high {
      background-color: $wt-green;
    }

    .low {
      background-color: $wt-red;
    }
  }
}
