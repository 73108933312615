@import '../../../styles/_colors.scss';

.home-navbar {
  position: sticky;
  height: 4rem;
  width: 100%;
  top: 0;
  background-color: rgba($black, 0.8);

  img.nav-logo {
    object-fit: contain;
    height: 100%;
    width: 10rem;
    padding: 0.25rem;
    margin-left: 1rem;
  }

  .home-nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contact-button {
    padding: 10px 20px;
    background-color: $wt-green;
    border-radius: 12px;
    align-items: center;
    margin-right: 16px;

    &:hover {
      filter: brightness(1.1);
    }
  }
}
