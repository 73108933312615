@import '../../styles/_colors.scss';

.call-to-action-container {
  width: calc(100vw - 4rem);
  height: auto;
  min-height: 35rem;
  padding: 2rem;
  margin: 2rem;
  margin-top: 5rem;
  border-radius: 10px;
  background: $color-linear-gradient;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner-content {
    width: 100%;
    height: auto;
    min-height: 31rem;
    height: calc(100% - 4rem);
    background-color: $black;
    border-radius: 10px;
    padding: 64px;
    box-shadow: $card-shadow;

    .left-panel {
      min-height: 21rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h1.main-title {
        width: 80%;
        max-width: 38rem;
        font-size: 3rem;
        font-weight: 500;
        color: $white;
      }

      p.sub-title {
        width: 80%;
        max-width: 38rem;
        font-size: 1rem;
        font-weight: 500;
        color: $gray-80;
      }

      button.get-started {
        background-color: $wt-green;
        font-size: 1rem;
        color: $white;
        padding: 10px 20px;
        border-radius: 12px;
        width: 150px;
        margin-left: 1rem;

        &:hover {
          filter: brightness(1.1);
        }
      }

      .social-container {
        max-width: 25rem;

        .follow-text {
          margin-right: 2rem;
        }

        margin-top: 1rem;
        display: flex;
        height: 2rem;
        display: flex;
        align-items: center;
      }
    }

    .image-container {
      position: relative;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.value-card-container {
  display: flex;
  justify-content: center;
  margin: 5rem;

  .value-text-container {
    h3.value-main-header {
      font-size: 40px;
      font-weight: 500;
      color: $white;
    }

    p.value-main-subtext {
      font-size: 1rem;
      color: $gray-80;
    }
  }

  .value-card {
    margin: 0.5rem;
    padding: 0.5rem;
    background-color: $dark-linear-gradient;
    box-shadow: $card-shadow;
    border-radius: 10px;

    h5.value-title {
      font-size: 1rem;
      font-weight: 500;
      color: $white;
    }

    p.value-subtitle {
      font-size: 0.875rem;
      color: $gray-80;
    }
  }
}
