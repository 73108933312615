@import '../../../../../styles/colors';

.table-container {
  background-color: $black;
  border: none !important;
  color: $white !important;

  .ats-header {
    min-width: 60px;
    text-align: center;

    .flavor {
      font-style: italic;
    }
  }
}

.datagrid-container {
  min-width: 500px;
}

.MuiDataGrid-iconSeparator,
.MuiDataGrid-sortIcon,
.MuiSvgIcon-root,
.MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon,
.MuiCheckbox-root {
  color: $white;
}

.MuiList-root {
  background-color: $black;
  color: $white;
}

.MuiPaper-root,
.MuiFormLabel-root {
  background-color: $black !important;
  box-shadow: none !important;
  color: $white !important;
}

.MuiDataGrid-row:nth-of-type(odd) {
  background-color: $gray-20;
}
.MuiDataGrid-row:nth-of-type(even) {
  background-color: $black;
}
.MuiDataGrid-cell,
.MuiDataGrid-columnHeaders {
  border: none !important;
}
