@import '../../../styles/_colors.scss';

nav.wt-main-navbar {
  position: sticky;
  background-color: $gray-20;
  height: 6rem;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;

  .navbar-collapse {
    background-color: rgba($gray-20, 0.95);

    @media only screen and (max-width: 1199px) {
      border-radius: 10px;
      z-index: 1000;
    }
  }

  .nav-logo {
    height: 4rem;
    width: 12rem;
    padding: 0 1rem;
    object-fit: contain;
  }

  .nav-link {
    color: $white;
    cursor: pointer;
    font-family: 'DM Sans';
    font-weight: 500;
    text-transform: capitalize;
  }

  .nav-link.selected {
    color: $wt-green !important;
  }

  .wt-bottom-nav-container {
    width: 100%;
    border-top: 1px solid $gray-40;

    @media only screen and (max-width: 1199px) {
      margin: 0.5rem;
      border: 0;
    }
  }

  .contact-button {
    padding: 10px 20px;
    border-radius: 12px;
    align-items: center;

    &:hover {
      background-color: $gray-40;
    }

    @media only screen and (max-width: 1199px) {
      display: none;
    }
  }

  .contact-nav {
    display: none;

    @media only screen and (max-width: 1199px) {
      display: block;
    }
  }
}
